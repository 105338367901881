import { SettingsObjectModel } from './settings-object'
import { types } from "mobx-state-tree"

const WARNING_MESSAGE =
  'This industry cannot be removed because there are grants attached to it. Remove grants from this industry in order to delete.'

const IndustryMixIn = types
  .model({})
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
  }))
  .actions((self) => ({}))

export const IndustryModel = types.compose(SettingsObjectModel, IndustryMixIn).named("IndustryModel")