import { types, applySnapshot, getSnapshot } from 'mobx-state-tree'
import { isEmpty } from "ramda"

const WARNING_MESSAGE =
  'This setting cannot be removed because there are grants attached to it. Remove grants from this setting in order to delete.'

export const SettingsObjectModel = types
  .model('SettingsObjectModel')
  .props({
    id: types.optional(types.identifierNumber, 0),
    name: types.optional(types.string, ''),
    hasGrantsAttached: types.optional(types.boolean, false),
    errorMessage: types.optional(types.string, ''),
  })
  .volatile(self => ({
    defaultState: {},
  }))
  .views(self => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
    get hasErrorMessage() {
      return self.errorMessage != ''
    },
    get canBeDestroyed() {
      return !self.hasGrantsAttached
    },
    get additionalProperties() {
      return []
    }
  }))
  .actions(self => ({
    setProp(field: string, value: string) {
      self[field] = value
    },
    reset() {
      applySnapshot(self, self.defaultState)
    },
    setErrorMessage(value: string) {
      self.errorMessage = value
    },
    afterCreate() {
      self.defaultState = getSnapshot(self)
    },
  }))

export type SettingsObject = typeof SettingsObjectModel.Type
