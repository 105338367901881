import * as React from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import { GeneralSettingsStore } from '../../stores/general-settings-store'
import { SettingsObject } from '../../models/settings-object'
import { SettingsCardMessage } from './settings-card-message'

export interface SettingsCard {
  nameInput?: any
}

export interface SettingsCardProps {
  generalSettingsStore?: GeneralSettingsStore
  settingsObject?: SettingsObject
}

export interface SettingsCardState {
  objectClone?: SettingsObject
}

@observer
export class SettingsCard extends React.Component<
  SettingsCardProps,
  SettingsCardState
> {
  state: SettingsCardState = { objectClone: toJS(this.props.settingsObject) }

  componentDidUpdate(prevProps, prevState) {
    const { currentObject } = this.props.generalSettingsStore
    if (currentObject && currentObject.id == this.props.settingsObject.id) {
      if (currentObject.additionalProperties.length == 0) {
        this.nameInput.focus()
      }
    }
  }

  enableObjectEditing = () => {
    const { generalSettingsStore, settingsObject } = this.props
    generalSettingsStore.setCurrentObject(settingsObject)
  }

  handleInputChanged = (field: string, value: string) => {
    this.setState(prevState => {
      let newObjectClone = prevState.objectClone
      newObjectClone[field] = value
      return {
        objectClone: newObjectClone,
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { generalSettingsStore } = this.props
    // once saved, set the value of the actual object and destroy its clone
    generalSettingsStore.updateSettingsObject(this.state.objectClone)
  }

  handleClose = () => {
    this.setState({
      objectClone: toJS(this.props.settingsObject),
    })

    // show/hide actions nav, disable editing
    this.props.generalSettingsStore.resetCurrentObject()
  }

  renderDeleteButton = () => {
    const { settingsObject, generalSettingsStore } = this.props
    return (
      <li>
        <a
          uk-icon="icon: trash"
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this item?'))
              generalSettingsStore.destroySettingsObject(settingsObject)
          }}
        />
      </li>
    )
  }

  renderDeleteWarning = warningString => {
    return <SettingsCardMessage messageText={warningString} />
  }

  renderErrorMessages = () => {
    const { errorMessage } = this.props.settingsObject
    return <SettingsCardMessage messageText={errorMessage} />
  }

  render(): any {
    const { generalSettingsStore, settingsObject } = this.props
    const { currentObject } = generalSettingsStore
    const { canBeDestroyed, additionalProperties } = settingsObject

    const objectIsBeingEdited = settingsObject == currentObject
    const showActionsClass = objectIsBeingEdited ? 'uk-hidden' : ''
    const showActionsConfirmClass = objectIsBeingEdited ? '' : 'uk-hidden'

    return (
      <form className="card settings-card" onSubmit={e => this.handleSubmit(e)}>
        <div className="settings-card__body">

            <input
              className="settings-card__name"
              value={this.state.objectClone.name}
              onChange={e => this.handleInputChanged('name', e.target.value)}
              placeholder="name"
              ref={input => {
                this.nameInput = input
              }}
              disabled={!objectIsBeingEdited}
            />

            {
              additionalProperties.map(property => 
                <input
                  className="settings-card__property ml-2"
                  placeholder={property.label}
                  key={`${property.name}`}
                  value={this.state.objectClone[property.name]}
                  onChange={e => this.handleInputChanged(property.name, e.target.value)}
                  ref={input => {
                    this.nameInput = input
                  }}
                  disabled={!objectIsBeingEdited}
                />
              )
            }
        
          <ul className={`uk-iconnav settings-actions ${showActionsClass}`}>
            <li>
              <a
                uk-icon="icon: file-edit"
                onClick={e => this.enableObjectEditing()}
              />
            </li>
            {canBeDestroyed && this.renderDeleteButton()}
          </ul>
          <ul
            className={`uk-iconnav settings-actions ${showActionsConfirmClass}`}
          >
            <li className={`settings-actions__submit`}>
              <button
                type="submit"
                uk-icon="icon: check"
                onClick={e => this.handleSubmit(e)}
              />
            </li>
            <li className={`settings-actions__close`}>
              <a uk-icon="icon: close" onClick={e => this.handleClose()} />
            </li>
          </ul>
        </div>
        {settingsObject.hasErrorMessage && this.renderErrorMessages()}
        {!canBeDestroyed &&
          this.renderDeleteWarning(settingsObject.deleteWarning)}
      </form>
    )
  }
}
